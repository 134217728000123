import { FadeIn } from "./animation"
import { MotionDiv } from "./animation/elements"
import { fadeInDown } from "./animation/fade"

function CTA() {
  return (
    <FadeIn
      tag="section"
      className="bg-cta py-12 md:py-24 lg:py-36 relative overflow-hidden  text-white lg:bg-fixed"
    >
      <div className="container">
        <MotionDiv
          variant={fadeInDown}
          className="-mx-4 flex items-center justify-center font-lf"
        >
          <div className="">
            <div className="mb-12 w-full text-center">
              <h2 className="mb-8 inline-block bg-lime-500 px-5 py-2 font-ms text-sm font-bold uppercase text-white">
                printing made easier
              </h2>
              <h2 className="tagline">Express printed quality paper bags</h2>
            </div>

            <div className="mx-auto flex w-full max-w-xl flex-col flex-wrap justify-between md:flex-row">
              <div className="flex justify-center md:justify-end">
                <a href="#" className="button yellowButton">
                  Request a quote
                </a>
              </div>
              <div className="flex items-center justify-center px-4 py-3 font-rw text-black md:py-0">
                <em>or</em>
              </div>
              <div className="flex justify-center md:justify-start">
                <a href="#" className="button altButton">
                  {" "}
                  Call +91 94953 56311{" "}
                </a>
              </div>
            </div>
          </div>
        </MotionDiv>
      </div>
    </FadeIn>
  )
}

export default CTA
