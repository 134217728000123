import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import CTA from "../components/CTA"
import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import { MotionDiv } from "../components/animation/elements"
import { FadeIn } from "../components/animation"
import { fadeInDown, fadeInUp } from "../components/animation/fade"

const headerProps = {
  title: "Products",
  caption: "Our assortment of Paper bags",
}

const Product = ({ title, thumbnail, path, children }) => (
  <FadeIn className="group w-full">
    <MotionDiv
      variant={fadeInDown}
      className="relative rounded-lg border border-gray-300 bg-white group-hover:shadow"
    >
      <Link to={path} className="overflow-hidden block">
        <div className="overflow-hidden block">
          <GatsbyImage
            className="w-full rounded-t-lg transition mt-6 hover:scale-110 duration-300 ease-in-out h-72 object-center object-cover lg:h-full"
            image={getImage(thumbnail)}
            alt={title}
          />
        </div>
        <div className="p-6 font-rw">
          <h5 className="productTitle mb-2">{title}</h5>
          <div className="columntext">
            <p>{children}</p>
          </div>
        </div>
      </Link>
    </MotionDiv>
  </FadeIn>
)

function Products({ data }) {
  const products = data.allMarkdownRemark.nodes
  return (
    <Layout>
      <SEO title="Products" />
      <Header {...headerProps} />
      <Container>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {products.map(product => (
            <Product
              key={product.frontmatter.title}
              title={product.frontmatter.title}
              thumbnail={product.frontmatter.featuredImage}
              path={product.fields.slug}
            >
              {product.frontmatter.description}
            </Product>
          ))}
        </div>
      </Container>
      <CTA />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ProductsPageQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "product" } } }
    ) {
      nodes {
        frontmatter {
          description
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 324, height: 324)
            }
          }
          title
        }
        fields {
          slug
        }
      }
    }
  }
`
export default Products
